<template>
  <v-row justify="center" class="my-15">
    <v-col cols="6">
      <v-card class="elevation-11 pa-4" color="blue lighten-5">
        <h1 class="text-center font-weight-black text-uppercase">Change Password</h1>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit}">
          <v-form ref="passwordForm" @submit.prevent="handleSubmit(handle_submit)">
            <v-row justify="center"
                   align="center" class="my-3">
              <v-col align-self="center"
                     cols=10>
                <ValidationProvider mode="eager" vid="current_password" name="Current Password" rules="required"
                                    v-slot="{ errors }">
                  <v-text-field
                      label="Current Password*"
                      hint="Current Password"
                      required
                      autocomplete="nope"
                      v-model="current_password"
                      type="password"
                  >
                  </v-text-field>
                  <span v-for="err in errors" :key="err" class="red--text">
                    <ul>
                      <li>
                        {{ err }}
                      </li>
                    </ul>
                  </span>
                </ValidationProvider>
                <ValidationProvider mode="eager" vid="new_password" name="New Password" rules="required"
                                    v-slot="{ errors }">
                  <v-text-field
                      label="New Password*"
                      hint="New Password"
                      required
                      autocomplete="nope"
                      v-model="new_password"
                      type="password"
                  >
                  </v-text-field>
                  <span v-for="err in errors" :key="err" class="red--text">
                    <ul>
                      <li>
                        {{ err }}
                      </li>
                    </ul>
                  </span>
                </ValidationProvider>
                <ValidationProvider mode="eager" vid="confirm_password" name="Confirm Password"
                                    rules="required|password:@new_password"
                                    v-slot="{ errors }">
                  <v-text-field
                      label="Confirm Password*"
                      hint="Confirm Password"
                      required
                      autocomplete="nope"
                      v-model="confirm_password"
                      type="password"
                  >
                  </v-text-field>
                  <span v-for="err in errors" :key="err" class="red--text">
                    <ul>
                      <li>
                        {{ err }}
                      </li>
                    </ul>
                  </span>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-col>
              <v-row justify="center"
                     align="center">
                <v-btn
                    color="blue darken-1"
                    text
                    rounded
                    type="submit"
                    elevation="4"
                    justify-center
                    class="ma-3"
                >
                  <v-icon class="mx-2">
                    mdi-orbit
                  </v-icon>
                  Submit
                </v-btn>
                <router-link :to="{name:'Dashboard'}" style="text-decoration: none; color: inherit">
                  <v-btn
                      color="blue darken-1"
                      text
                      rounded
                      type="submit"
                      elevation="4"
                      justify-center
                      class="ma-3"
                  >
                    <v-icon class="px-1">mdi-view-dashboard</v-icon>
                    Dashboard
                  </v-btn>
                </router-link>
              </v-row>
            </v-col>
          </v-form>
        </ValidationObserver>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import "@/validations/office_validations"

export default {
  name: "ChangePassword",
  computed: {
    axios_conf() {
      axios.defaults.baseURL = this.$store.state.baseURL
      axios.defaults.headers.common['Authorization'] = `mamla ${this.$store.state.accessToken}`
      return 0
    },
  },
  data: () => ({
    current_password: '',
    new_password: '',
    confirm_password: ''
  }),
  methods: {
    async handle_submit() {
      this.axios_conf
      let data = {new_password: this.new_password, current_password: this.current_password}
      await axios.post('user/change_password', data)
          .then((resp) => {
            this.$store.commit('setJWT', resp.data.key)
            this.dialog = false
            this.$emit('form_status', true)
            this.$emit('snackbar_st', `Password change is successful`)
            this.$store.commit('setPassReset', false)
            this.$router.push({name: "Dashboard"})
          })
          .catch((error) => {
            // console.log(error)
            if (error.response.data) {
              this.$store.commit('setJWT', error.response.data.key)
              console.log(error.response.data.key)
              this.$refs.observer.setErrors({
                current_password: error.response.data.errors.current_password,
                new_password: error.response.data.errors.new_password,
              });
            }
          })
    }
  },
  components: {ValidationProvider, ValidationObserver},
}
</script>

<style scoped>

</style>