var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"my-15",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{staticClass:"elevation-11 pa-4",attrs:{"color":"blue lighten-5"}},[_c('h1',{staticClass:"text-center font-weight-black text-uppercase"},[_vm._v("Change Password")]),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{ref:"passwordForm",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.handle_submit)}}},[_c('v-row',{staticClass:"my-3",attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"align-self":"center","cols":"10"}},[_c('ValidationProvider',{attrs:{"mode":"eager","vid":"current_password","name":"Current Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Current Password*","hint":"Current Password","required":"","autocomplete":"nope","type":"password"},model:{value:(_vm.current_password),callback:function ($$v) {_vm.current_password=$$v},expression:"current_password"}}),_vm._l((errors),function(err){return _c('span',{key:err,staticClass:"red--text"},[_c('ul',[_c('li',[_vm._v(" "+_vm._s(err)+" ")])])])})]}}],null,true)}),_c('ValidationProvider',{attrs:{"mode":"eager","vid":"new_password","name":"New Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"New Password*","hint":"New Password","required":"","autocomplete":"nope","type":"password"},model:{value:(_vm.new_password),callback:function ($$v) {_vm.new_password=$$v},expression:"new_password"}}),_vm._l((errors),function(err){return _c('span',{key:err,staticClass:"red--text"},[_c('ul',[_c('li',[_vm._v(" "+_vm._s(err)+" ")])])])})]}}],null,true)}),_c('ValidationProvider',{attrs:{"mode":"eager","vid":"confirm_password","name":"Confirm Password","rules":"required|password:@new_password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Confirm Password*","hint":"Confirm Password","required":"","autocomplete":"nope","type":"password"},model:{value:(_vm.confirm_password),callback:function ($$v) {_vm.confirm_password=$$v},expression:"confirm_password"}}),_vm._l((errors),function(err){return _c('span',{key:err,staticClass:"red--text"},[_c('ul',[_c('li',[_vm._v(" "+_vm._s(err)+" ")])])])})]}}],null,true)})],1)],1),_c('v-col',[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-btn',{staticClass:"ma-3",attrs:{"color":"blue darken-1","text":"","rounded":"","type":"submit","elevation":"4","justify-center":""}},[_c('v-icon',{staticClass:"mx-2"},[_vm._v(" mdi-orbit ")]),_vm._v(" Submit ")],1),_c('router-link',{staticStyle:{"text-decoration":"none","color":"inherit"},attrs:{"to":{name:'Dashboard'}}},[_c('v-btn',{staticClass:"ma-3",attrs:{"color":"blue darken-1","text":"","rounded":"","type":"submit","elevation":"4","justify-center":""}},[_c('v-icon',{staticClass:"px-1"},[_vm._v("mdi-view-dashboard")]),_vm._v(" Dashboard ")],1)],1)],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }