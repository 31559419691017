<template>
  <v-container>
    <change-password/>
  </v-container>
</template>

<script>

import ChangePassword from "@/components/reset_password_components/ChangePassword";

export default {
  name: "ChangePasswordPage",
  components: {ChangePassword}

}
</script>

<style scoped>

</style>